import React, { createContext, useContext, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import PageQuery from 'components/PageQuery'
import { Container, LinkButton } from '@unowmooc/react-ui-kit'
import I18nProvider from '@unowmooc/i18n'
import { Col, Row } from 'react-grid-system'
import { Link, Redirect } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import styled from '@emotion/styled'
import BasicContent from 'components/BasicContent'
import SessionCanceled from 'modules/session/components/SessionCanceled'
import { useUserContext } from 'store/UserProvider'
import { isSessionStarted } from 'modules/session/utils'
import ShowSectionAccessError from 'pages/domains/courses/sections/ShowSectionAccessError'
import moment from 'moment'
import useToggle from 'hooks/useToggle'
import WriteMessageModal from 'modules/conversations/components/WriteMessageParticipantExpertModalForm'
import { LocaleContext } from 'components/LocaleProvider'
import { getSkillsFinalForm } from 'modules/session/session.utils'
import { isParticipationStarted } from 'modules/participant/utils'
import { COURSE_FORMAT } from 'business/course'
import lmsBuilder from 'business/lms'
import useBreadcrumbUtils from './useBreadcrumbUtils'

const RowWrapper = styled(Row)`
  padding-top: 100px;
  text-align: center;
`

const CourseContext = createContext({
  hasReadGuide: true,
  hasBic: false,
  hasBfc: false,
  commonSkillsInitialForm: null,
  specificSkillsInitialForm: null,
  sessionId: undefined,
  participantId: undefined,
})

export const CourseContextProvider = ({ sessionId, slugCourse, routerLocation, children }) => (
  <PageQuery
    force
    query={gql`
      query CourseContextProvider($id: ID!) {
        session(id: $id) {
          id
          identifier
          startAt
          endAt
          confirmedAt
          canceledAt
          challengesAcceptancePageUrl
          conversationsEnabled
          hasGuide
          virtualClassroomEvaluationForm {
            formId
            contextPrefix
          }
          hasVirtualClassroom
          hasOnSiteVirtualClassroom
          hasRSVP
          authenticatedParticipant {
            id
            hasReadGuide
            formAdvancementStates {
              answeredCommonSkillsInitialForm
              answeredSpecificSkillsInitialForm
              answeredCommonSkillsFinalForm
              answeredSpecificSkillsFinalForm
              answeredOnboardingSkillsInitialForm
            }
            user {
              id
              isLms
            }
            timeSpentHours
            lastFinalExamQuizAttempt {
              id
              score
              scorePercentage
              status
            }
            acceptedChallenges {
              id
              challenge {
                id
                label
              }
            }
            badges {
              id
            }
            progressions {
              id
            }
            type
            haveChallengesBeenUseful
            advancementStatus
            certificateNumber
            professionalCertification {
              id
              label
              isUnow
              certificatorName
            }
            hasUsedCpf
          }
          course {
            id
            code
            slug
            identifier
            title
            locale
            hasFlexibleStart
            format
          }
          forms {
            commonSkillsInitialForm {
              id
              duration
              questionCount
            }
            specificSkillsInitialForm {
              id
              duration
              questionCount
            }
            commonSkillsFinalForm {
              id
              duration
              questionCount
            }
            specificSkillsFinalForm {
              id
              duration
              questionCount
            }
            onboardingSkillsInitialForm {
              id
              duration
              questionCount
            }
            satisfactionNonFinisherForm {
              id
              duration
            }
          }
          mainAnimator {
            id
            fullName
            avatar {
              id
              secureUrl
            }
            gender
          }
        }
      }
    `}
    variables={{
      id: sessionId,
    }}
    render={(renderProps, { refetch }) => (
      <CourseContextProviderRenderer
        {...renderProps}
        refetchQuery={refetch}
        sessionId={sessionId}
        slugCourse={slugCourse}
        routerLocation={routerLocation}
      >
        {children}
      </CourseContextProviderRenderer>
    )}
  />
)

const CourseContextProviderRenderer = ({ session, slugCourse, routerLocation, refetchQuery, children }) => {
  const { locale, switchLocale } = useContext(LocaleContext)
  const localeRef = useRef(locale)
  const breadcrumbUtils = useBreadcrumbUtils()
  const { isAdmin, isAnimatorFromSession } = useUserContext()
  const [
    writeMessageAnimatorModal,
    { open: openWriteMessageAnimatorModal, close: closeWriteMessageAnimatorModal },
  ] = useToggle()

  useEffect(() => {
    switchLocale(session?.course.locale ?? localeRef.current)

    return () => {
      switchLocale(localeRef.current)
    }
  }, [session?.course, localeRef])

  // Si on n'arrive pas à récupérer de session, c'est que nous ne sommes pas inscrit dessus.
  // La gestion Admin et Animateur est faite côté Back
  if (!session) {
    return (
      <Container>
        <RowWrapper>
          <Col xs={12}>
            <BasicContent i18nTitle="courses.course.not_registered.title" />
            <LinkButton theme="highlight" tag={Link} to={I18nProvider.getLinkRoute('/dashboard')}>
              <FormattedMessage id="courses.course.not_registered.back_to_home_link" />
            </LinkButton>
          </Col>
        </RowWrapper>
      </Container>
    )
  }

  const sessionUrl = I18nProvider.getLinkRoute('/courses/{slugCourse}/{idSession}', {
    slugCourse: session.course.slug,
    idSession: session.id,
  })

  if (slugCourse !== session.course.slug) {
    return (
      <Redirect
        to={`${routerLocation.pathname.replace(
          I18nProvider.getLinkRoute('/courses/{slugCourse}/{idSession}', { slugCourse, idSession: session.id }),
          sessionUrl,
        )}${routerLocation.search}`}
      />
    )
  }

  if (session.canceledAt) {
    return (
      <Container>
        <SessionCanceled />
      </Container>
    )
  }

  const bic = [
    {
      type: 'specific',
      ...(session.forms.specificSkillsInitialForm ?? {}),
      exists: !!session.forms.specificSkillsInitialForm?.id,
      answered: !!session.authenticatedParticipant?.formAdvancementStates?.answeredSpecificSkillsInitialForm,
    },
    {
      type: 'common',
      ...(session.forms.commonSkillsInitialForm ?? {}),
      exists: !!session.forms.commonSkillsInitialForm?.id,
      answered: !!session.authenticatedParticipant?.formAdvancementStates?.answeredCommonSkillsInitialForm,
    },
  ].filter(survey => survey.exists)

  const { bfc, hasBfc, hasBfcAnswered, hasSpecificBfc } = getSkillsFinalForm(
    session.forms,
    session.authenticatedParticipant?.formAdvancementStates,
  )
  const hasBic = bic.length > 0
  const hasBicAnswered = bic.every(survey => survey.answered)

  const hasBicOnboarding = !!session.forms.onboardingSkillsInitialForm
  const bicOnboarding = hasBicOnboarding
    ? {
        ...(session.forms.onboardingSkillsInitialForm ?? {}),
        answered: !!session.authenticatedParticipant?.formAdvancementStates?.answeredOnboardingSkillsInitialForm,
      }
    : null

  const sessionIsStarted = isSessionStarted(session)

  const participationStarted = isParticipationStarted({
    badges: session.authenticatedParticipant?.badges,
    progressions: session.authenticatedParticipant?.progressions,
  })

  const getErrorsShowModule = ({ isModuleZero = true } = {}) => {
    const errors = []

    if (
      isModuleZero ||
      isAdmin() ||
      isAnimatorFromSession(session.id) ||
      session.authenticatedParticipant?.type === 'demo'
    ) {
      return errors
    }

    if (!sessionIsStarted) {
      errors.push(ShowSectionAccessError.types.NOT_STARTED)
    }

    if (hasBic && !hasBicAnswered) {
      errors.push(ShowSectionAccessError.types.BIC_NOT_ANSWERED)
    }

    return errors
  }

  const advancementStatus = session.authenticatedParticipant?.advancementStatus ?? null

  let lms
  if (session.authenticatedParticipant?.user?.isLms) {
    lms = lmsBuilder({
      sessionId: session.id,
      advancementStatus,
      lastFinalExamQuizAttemptScore: session.authenticatedParticipant?.lastFinalExamQuizAttempt?.scorePercentage,
      lastFinalExamQuizAttemptStatus: session.authenticatedParticipant?.lastFinalExamQuizAttempt?.status,
      timeSpentHours: session.authenticatedParticipant?.timeSpentHours,
    })
  } else {
    lms = {
      sendDataToLmsConnector: () => {},
    }
  }

  return (
    <CourseContext.Provider
      value={{
        sessionId: session.id,
        slugCourse,
        courseCode: session.course.code,
        courseIdentifier: session.course.identifier,
        courseTitle: session.course.title,
        courseLocale: session.course.locale,
        courseFormat: session.course.format,
        virtualClassroomEvaluationForm: session.virtualClassroomEvaluationForm,
        sessionIdentifier: session.identifier,
        sessionHasConversationsEnabled: session.conversationsEnabled,
        sessionUrl,
        sessionStartAt: session.startAt,
        sessionIsStarted,
        participationStarted,
        challengesAcceptancePageUrl: session.challengesAcceptancePageUrl,
        mainAnimator: session.mainAnimator,
        participantId: session.authenticatedParticipant?.id,
        acceptedChallenges: session.authenticatedParticipant?.acceptedChallenges ?? [],
        haveChallengesBeenUseful: session.authenticatedParticipant?.haveChallengesBeenUseful,
        satisfactionNonFinisherFormId: session?.forms?.satisfactionNonFinisherForm?.id,
        hasReadGuide: session.authenticatedParticipant?.hasReadGuide ?? true,
        hasGuide: session.hasGuide,
        hasUsedCpf: session.authenticatedParticipant?.hasUsedCpf,
        hasVirtualClassroom: session.hasVirtualClassroom,
        hasOnSiteVirtualClassroom: session.hasOnSiteVirtualClassroom,
        hasRSVP: session.hasRSVP,
        isCustom: session.course.format === 'custom',
        getErrorsShowModule,
        advancementStatus,
        certificateNumber: session.authenticatedParticipant?.certificateNumber ?? null,
        professionalCertification: session.authenticatedParticipant?.professionalCertification ?? null,
        bic,
        hasBic,
        hasBicAnswered,
        bfc,
        hasBfc,
        hasBfcAnswered,
        hasSpecificBfc,
        bicOnboarding,
        hasBicOnboarding,
        hasBfcAvailable: moment().isSameOrAfter(moment(session.endAt).add(1, 'month')),
        refetchQuery,
        breadcrumbUtils,
        openWriteMessageAnimatorModal,
        lms,
      }}
    >
      {children}
      {writeMessageAnimatorModal && (
        <WriteMessageModal
          animatorUserId={session.mainAnimator?.id}
          sessionId={session.id}
          onClose={closeWriteMessageAnimatorModal}
        />
      )}
    </CourseContext.Provider>
  )
}

CourseContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  sessionId: PropTypes.string.isRequired,
  slugCourse: PropTypes.string.isRequired,
  routerLocation: PropTypes.shape({}).isRequired,
}

CourseContextProviderRenderer.defaultProps = {
  session: null,
}

CourseContextProviderRenderer.propTypes = {
  children: PropTypes.node.isRequired,
  session: PropTypes.shape({
    id: PropTypes.string.isRequired,
    identifier: PropTypes.string.isRequired,
    startAt: PropTypes.string,
    endAt: PropTypes.string,
    canceledAt: PropTypes.string,
    challengesAcceptancePageUrl: PropTypes.string,
    conversationsEnabled: PropTypes.bool.isRequired,
    hasGuide: PropTypes.bool.isRequired,
    virtualClassroomEvaluationForm: PropTypes.shape({
      formId: PropTypes.number.isRequired,
      contextPrefix: PropTypes.string.isRequired,
    }),
    hasVirtualClassroom: PropTypes.bool.isRequired,
    hasOnSiteVirtualClassroom: PropTypes.bool.isRequired,
    hasRSVP: PropTypes.bool.isRequired,
    authenticatedParticipant: PropTypes.shape({
      id: PropTypes.string.isRequired,
      hasReadGuide: PropTypes.bool.isRequired,
      type: PropTypes.string.isRequired,
      timeSpentHours: PropTypes.number.isRequired,
      formAdvancementStates: PropTypes.shape({
        answeredCommonSkillsInitialForm: PropTypes.bool.isRequired,
        answeredSpecificSkillsInitialForm: PropTypes.bool.isRequired,
        answeredCommonSkillsFinalForm: PropTypes.bool.isRequired,
        answeredSpecificSkillsFinalForm: PropTypes.bool.isRequired,
        answeredOnboardingSkillsInitialForm: PropTypes.bool.isRequired,
      }),
      user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        isLms: PropTypes.bool,
      }).isRequired,
      lastFinalExamQuizAttempt: PropTypes.shape({
        id: PropTypes.string.isRequired,
        score: PropTypes.number.isRequired,
        scorePercentage: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired,
      }),
      acceptedChallenges: PropTypes.shape({
        id: PropTypes.string.isRequired,
        challenge: PropTypes.shape({
          id: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        }),
      }),
      badges: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
        }),
      ).isRequired,
      progressions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
        }),
      ).isRequired,
      haveChallengesBeenUseful: PropTypes.bool,
      advancementStatus: PropTypes.string.isRequired,
      certificateNumber: PropTypes.string,
      professionalCertification: PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string,
        isUnow: PropTypes.bool.isRequired,
        certificatorName: PropTypes.string,
      }),
      hasUsedCpf: PropTypes.bool.isRequired,
    }),
    course: PropTypes.shape({
      id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      identifier: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      format: PropTypes.oneOf(Object.values(COURSE_FORMAT)),
      locale: PropTypes.string.isRequired,
    }).isRequired,
    mainAnimator: PropTypes.shape({
      id: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
      avatar: PropTypes.shape({
        secureUrl: PropTypes.string.isRequired,
      }),
      gender: PropTypes.string,
    }),
    forms: PropTypes.shape({
      commonSkillsInitialForm: PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
      specificSkillsInitialForm: PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
      commonSkillsFinalForm: PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
      specificSkillsFinalForm: PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
      onboardingSkillsInitialForm: PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
      satisfactionNonFinisherForm: PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
    }).isRequired,
  }),
  slugCourse: PropTypes.string.isRequired,
  routerLocation: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
  }).isRequired,
  refetchQuery: PropTypes.func.isRequired,
}

export const useCourseContext = () => useContext(CourseContext)
