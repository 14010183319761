import React, { createContext, useContext, useState, useEffect, useMemo } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import { withRouter } from 'react-router-dom'
import { client as apolloClient } from 'utils/graphql'
import { captureException } from '@sentry/browser'
import { MINIMUM_CORRECT_ANSWERS_POURCENT, QUIZ_ATTEMPT_STATUS } from '../constants'
import { createPathUtils } from './pathUtils'

const QuizContext2 = createContext({
  status: null,
  score: null,
  questionCounter: 0,
  minimumCorrectAnswerCounter: 0,
  questionIndex: null,
  currentQuestion: null,
  pendingAnswer: null,
  updatePendingAnswer: () => {},
  startQuiz: () => {},
  validateAnswer: () => {},
  nextQuestion: () => {},
  isValid: () => {},
  remainingAttempts: 0,
  getIntroductionPath: () => {},
  getQuestionPath: () => {},
  getResultsPath: () => {},
})

export const QUIZ_ATTEMPT_FRAGMENT = gql`
  fragment QuizAttemptFragment on QuizAttempt {
    id
    quizQuestionAttempts {
      id
      quizQuestion {
        id
        text
        hasManyValidChoices
        choices {
          id
          text
          isCorrect
        }
        explanation
      }
      status
    }
    status
    score
    scorePercentage
  }
`

const CREATE_QUIZ_ATTEMPT = gql`
  mutation createQuizAttempt($participantId: ID!, $moduleId: ID) {
    quizAttempt: createQuizAttempt(participantId: $participantId, moduleId: $moduleId) {
      ...QuizAttemptFragment
    }
  }
  ${QUIZ_ATTEMPT_FRAGMENT}
`

const VALIDATE_QUIZ_QUESTION_ANSWER = gql`
  mutation validateQuizQuestionAnswer($quizQuestionAttemptId: ID!, $answers: [ID!]!) {
    quizAttempt: validateQuizQuestionAnswer(quizQuestionAttemptId: $quizQuestionAttemptId, answers: $answers) {
      ...QuizAttemptFragment
    }
  }
  ${QUIZ_ATTEMPT_FRAGMENT}
`

// Ici on récupère à la fois le advancementStatus et le advancement.status
// car dans l'API GraphQL on a deux fois la même information et ça permet de la raffraichir
// une fois le quiz passé (et permet notamment de mettre à jour cette valeur dans le
// CourseContext qui sera utilisée pour les envois d'infos dans le LMS).
const REFRESH_PARTICIPANT_BADGES_AFTER_QUIZ_SUCCESS = gql`
  query refreshParticipantBadgesAfterQuizSuccess($sessionId: ID!) {
    session(id: $sessionId) {
      id
      authenticatedParticipant {
        id
        advancementStatus
        certificateNumber
        certificateValidatedAt
        professionalCertificationNumber
        badges {
          id
          module {
            id
          }
        }
        lastFinalExamQuizAttempt {
          ...QuizAttemptFragment
        }
        advancement {
          status
          nbRemainedExamAttempt
        }
      }
    }
  }
  ${QUIZ_ATTEMPT_FRAGMENT}
`

export const QuizProvider = withRouter(
  ({
    children,
    history,
    moduleId = null,
    remainingAttempts: remainingAttemptsProps = 0,
    lastFinalExamQuizAttempt = null,
    questionCounter = 0,
  }) => {
    const { participantId, sessionId, slugCourse, lms } = useCourseContext()

    const [quizState, updateQuizState] = useState(null)
    const [questionIndex, updateQuestionIndex] = useState(null)
    const [pendingAnswer, updatePendingAnswer] = useState(null)
    const [minimumCorrectAnswerCounter, updateMinimumCorrectAnswerCounter] = useState(null)
    const [remainingAttempts, updateRemainingAttempts] = useState(remainingAttemptsProps)

    const [createQuizAttempt] = useMutation(CREATE_QUIZ_ATTEMPT)
    const [validateQuizQuestionAnswer] = useMutation(VALIDATE_QUIZ_QUESTION_ANSWER)

    const { getBasePath, getIntroductionPath, getQuestionPath, getResultsPath } = useMemo(
      () => createPathUtils(slugCourse, sessionId, moduleId),
      [slugCourse, sessionId, moduleId],
    )

    useEffect(() => {
      // On ne récupère la valeur de l'ancienne tentative uniquement si celle-ci a été finalisée
      if (lastFinalExamQuizAttempt && lastFinalExamQuizAttempt.status !== QUIZ_ATTEMPT_STATUS.PROCESSING) {
        updateQuizState(lastFinalExamQuizAttempt)
      }
    }, [lastFinalExamQuizAttempt])

    useEffect(() => {
      if (questionIndex !== null) {
        history.push(getQuestionPath(questionIndex + 1))
      }
    }, [questionIndex])

    useEffect(() => {
      updateMinimumCorrectAnswerCounter(Math.ceil(questionCounter * MINIMUM_CORRECT_ANSWERS_POURCENT))
    }, [questionCounter])

    const startQuiz = async () => {
      // Avant de démarrer le quiz on s'assure qu'il soit réinitialisé.
      updateQuizState(null)
      updateQuestionIndex(null)
      updatePendingAnswer(null)

      const response = await createQuizAttempt({
        variables: {
          participantId,
          moduleId,
        },
      })

      updateQuizState(response.data.quizAttempt)
      updateQuestionIndex(0)

      // Décrémente le nombre de tentatives restantes
      if (remainingAttempts > 0) {
        updateRemainingAttempts(remainingAttempts - 1)
      }
    }

    const validateAnswer = async () => {
      const response = await validateQuizQuestionAnswer({
        variables: {
          quizQuestionAttemptId: quizState?.quizQuestionAttempts?.[questionIndex]?.id,
          answers: (Array.isArray(pendingAnswer) ? pendingAnswer : [pendingAnswer]).map(answer => answer.id),
        },
      })

      const newQuizAttempt = response.data.quizAttempt
      updateQuizState(newQuizAttempt)

      // ici même si on est pas en succès il faut faire l'appel pour faire le refresh
      // ça va modifier le cache et le résultat de lastFinalExamQuizAttempt dans le CourseContext
      // et is on est en lms permettra de renvoyer la bonne valeur au lieu de sendDataToLmsConnector
      apolloClient
        .query({
          query: REFRESH_PARTICIPANT_BADGES_AFTER_QUIZ_SUCCESS,
          fetchPolicy: 'network-only',
          variables: { sessionId },
        })
        .catch(refreshBadgesError => {
          captureException(
            new Error(`[query refreshParticipantBadgesAfterQuizSuccess]: ${JSON.stringify(refreshBadgesError)}`),
          )
        })
    }

    const nextQuestion = () => {
      // Le quiz est terminé, affichage de la page de résultats
      if ([QUIZ_ATTEMPT_STATUS.FAILURE, QUIZ_ATTEMPT_STATUS.SUCCESS].includes(quizState.status)) {
        history.push(getResultsPath())
        updateQuestionIndex(null)
        updatePendingAnswer(null)

        lms.sendDataToLmsConnector()

        return
      }

      // On continue de naviguer dans le quiz
      updateQuestionIndex(questionIndex + 1)
      updatePendingAnswer(null)
    }

    const isValid = ({ onValid } = {}) => {
      if (quizState === null) {
        history.push(getIntroductionPath())

        return
      }

      onValid?.()
    }

    return (
      <QuizContext2.Provider
        value={{
          status: quizState?.status ?? null,
          score: quizState?.score ?? null,
          questionCounter,
          minimumCorrectAnswerCounter,
          questionIndex,
          currentQuestion: quizState?.quizQuestionAttempts[questionIndex] ?? null,
          pendingAnswer,
          updatePendingAnswer,
          startQuiz,
          validateAnswer,
          nextQuestion,
          isValid,
          remainingAttempts,
          getBasePath,
          getIntroductionPath,
          getQuestionPath,
          getResultsPath,
        }}
      >
        {children}
      </QuizContext2.Provider>
    )
  },
)

export const useQuiz = () => useContext(QuizContext2)
