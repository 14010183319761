import React, { useEffect, useRef } from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { rgba } from '@unowmooc/themes'
import PropTypes from 'prop-types'

const textImportant = css`
  margin: 30px 0;
  padding: 18px 20px;

  & p:last-child {
    margin-bottom: 0;
  }
`

const WysiwygViewWrapper = styled.div(
  ({ theme: { colors } }) => css`
    iframe {
      width: 100%;
    }

    h2 {
      font-size: 28px;
      line-height: 38px;
      margin: 0 0 24px 0;
    }

    h3 {
      font-size: 24px;
      line-height: 32px;
      margin: 0 0 12px 0;
    }

    h4 {
      font-size: 20px;
      line-height: 30px;
      margin: 0 0 4px 0;
    }

    p {
      margin-bottom: 36px;
      color: ${colors.woodsmoke};
    }

    /* quotes */
    blockquote {
      border-left: 1px solid ${colors.unowGreen};
      padding-left: 15px;
      margin: 0 0 36px 0;

      > p {
        text-align: left !important;
        font-style: italic;

        &:before {
          margin-right: 5px;
          content: '«';
        }

        &:after {
          margin-left: 5px;
          content: '»';
        }
      }

      & blockquote,
      & blockquote blockquote {
        padding: 0;
        margin: 0;
        border: 0;

        p {
          font-size: 18px;
          line-height: 32px;
        }
      }
    }

    /* paragraphs */
    .fr-text-important {
      ${textImportant};
    }

    .fr-text-spoiler {
      ${textImportant};
      filter: blur(4px);
      cursor: pointer;
      border: 1px dashed black;
    }

    .fr-text-spoiler-displayed {
      filter: none;
      cursor: auto;
      border: none;
    }

    .fr-text-important-yellow {
      ${textImportant};
      background-color: ${rgba(colors.unowOrange, 0.1)};
    }

    .fr-text-important-green {
      ${textImportant};
      background-color: ${rgba(colors.unowGreen, 0.1)};
    }

    .fr-text-important-bluegreen {
      ${textImportant};
      background-color: ${rgba(colors.unowBluegreen, 0.1)};
    }

    .fr-important {
      font-size: 24px;
      line-height: 32px;
      color: ${colors.unowBluegreen};
      font-weight: normal;
    }

    /* listing */
    p + ul,
    p + ol {
      margin-top: -26px;
    }

    ul,
    ol {
      padding-left: 20px;
      margin: 0 0 40px 0;

      li {
        margin-bottom: 8px;
        font-size: 16px;
        line-height: 25px;
        color: ${colors.woodsmoke};

        > p {
          display: inline;
        }

        ul,
        ol {
          margin: 8px 0;
        }
      }
    }

    ul li {
      list-style-type: disc;
    }

    ol li {
      list-style-type: numeric;
    }

    /* code */
    pre.prettyprint,
    code.prettyprint {
      width: 100%;
      margin: 1em 0 !important;
      background-color: #29282c;
      box-shadow: 0 0 0;
      border-radius: 0;
    }

    /* table */
    table {
      width: 100%;
    }
    table td,
    table th {
      padding: 5px;
    }

    table td,
    table td ul > li {
      font-size: 14px;
      line-height: 24px;
    }

    /* file */
    .fr-file {
      display: inline-block;
      width: auto;
      background: white;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.14);

      &::after {
        display: none;
      }

      &::before {
        font-family: StreamlineIcons;
        vertical-align: middle;
        padding-right: 8px;
      }
    }

    img {
      display: block;
      margin: 5px auto;
      max-width: 100%;

      &.fr-dii {
        display: inline-block;
        vertical-align: middle;
        margin-left: 15px;
        margin-right: 15px;
        max-width: calc(100% - (2 * 15px));

        /* align left */
        &.fr-fil {
          margin: 5px 15px 15px 0;
          max-width: calc(100% - 15px);
          padding-right: 15px;
        }

        /* align right */
        &.fr-fir {
          margin: 5px 0 15px 15px;
          max-width: calc(100% - 15px);
          padding-left: 15px;
        }
      }
    }

    .fr-img-caption {
      display: block;
      text-align: center;

      &.fr-dib {
        margin: 5px auto;

        &.fr-fil {
          margin-left: 0;
          text-align: left;
        }

        &.fr-fir {
          margin-right: 0;
          text-align: right;
        }
      }

      .fr-img-wrap > span {
        display: block;
        padding: 0;
        margin-top: 10px;
        font-size: 14px;
        line-height: 25px;
        opacity: 1;
        color: ${colors.midGray};
        text-align: center;
      }
    }
  `,
)

const WysiwygView = ({ content }) => {
  const elementRef = useRef(null)

  const handleClick = element => {
    const { classList } = element.target
    if (classList.contains('fr-text-spoiler') && !classList.contains('fr-text-spoiler-displayed')) {
      classList.add('fr-text-spoiler-displayed')
    } else {
      const closestSpoiler = element.target.closest('.fr-text-spoiler')
      if (closestSpoiler) {
        if (
          closestSpoiler.classList.contains('fr-text-spoiler') &&
          !closestSpoiler.classList.contains('fr-text-spoiler-displayed')
        ) {
          closestSpoiler.classList.add('fr-text-spoiler-displayed')
        }
      }
    }
  }

  useEffect(() => {
    elementRef.current.addEventListener('click', handleClick, true)
  }, [])

  return <WysiwygViewWrapper ref={elementRef} dangerouslySetInnerHTML={{ __html: content }} />
}

WysiwygView.propTypes = {
  content: PropTypes.string.isRequired,
}

export default WysiwygView
