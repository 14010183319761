import React, { useEffect, useState } from 'react'
import { Button } from '@unowmooc/buttons'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import I18nProvider from '@unowmooc/i18n'
import { useMutation } from '@apollo/react-hooks'
import { captureException } from '@sentry/browser'
import Helmet from 'react-helmet'
import { animateScrollTo } from 'utils/scroll'
import SlideLayout from './components/SlideLayout'
import Breadcrumb from './components/Breadcrumb'
import { useCourseContext } from '../course/components/CourseContext/CourseContext'
import OnboardingSlideContent from './components/SlideContent/Onboarding'
import CertificationSlideContent from './components/SlideContent/Certification'
import PaceSlideContent from './components/SlideContent/Pace'
import VirtualClassroomSlideContent from './components/SlideContent/VirtualClassroom'
import CollectorSlideContent from './components/SlideContent/Collector'
import GoalsSlideContent from './components/SlideContent/Goals'

const onboardingMobileImage = require('./assets/onboarding-mobile.png')
const onboardingDesktopImage = require('./assets/onboarding-desktop.png')
const certificationMobileImage = require('./assets/certification-mobile.png')
const certificationDesktopImage = require('./assets/certification-desktop.png')
const paceMobileImage = require('./assets/pace-mobile.png')
const paceDesktopImage = require('./assets/pace-desktop.png')
const virtualClassroomMobileImage = require('./assets/virtualClassroom-mobile.png')
const virtualClassroomDesktopImage = require('./assets/virtualClassroom-desktop.png')
const goalsMobileImage = require('./assets/goals-mobile.png')
const goalsDesktopImage = require('./assets/goals-desktop.png')
const collectorMobileImage = require('./assets/collector-mobile.png')
const collectorDesktopImage = require('./assets/collector-desktop.png')

const SET_GUIDE_HAS_READ = gql`
  mutation setGuideHasRead($participantId: ID!) {
    hasReadGuide(participantId: $participantId)
  }
`

const Guide = ({
  history: { push },
  match: {
    params: { slidePage },
  },
  getSlidePage,
  onLeave,
  onFinish,
  lightMode,
}) => {
  const {
    participantId,
    courseTitle,
    isCustom,
    hasUsedCpf,
    hasRSVP,
    hasVirtualClassroom,
    hasBic,
    hasBfc,
    hasBicOnboarding,
    bicOnboarding,
    refetchQuery,
  } = useCourseContext()

  const [slideConfiguration, updateSlideConfiguration] = useState(null)
  const [nextButtonDisabled, updateNextButtonDisabled] = useState(false)

  const [setGuideHasRead] = useMutation(SET_GUIDE_HAS_READ)

  const handleFinish = () => {
    setGuideHasRead({ variables: { participantId } })
      .then(refetchQuery)
      .then(onFinish)
      .catch(error => {
        captureException(new Error(`[Guide hasReadGuide mutation error]: ${JSON.stringify(error)}`))
      })
  }

  // Slides définitions
  const SLIDE_ONBOARDING = {
    key: 'onboarding',
    title: <FormattedHTMLMessage id="guide.slides.onboarding.title" values={{ courseTitle }} />,
    pictureUrls: {
      mobile: onboardingMobileImage,
      desktop: onboardingDesktopImage,
    },
    breadcrumb: <FormattedHTMLMessage id="guide.slides.onboarding.breadcrumb" />,
    content: <OnboardingSlideContent />,
  }

  const SLIDE_CERTIFICATION = {
    key: 'certification',
    title: <FormattedHTMLMessage id="guide.slides.certification.title" />,
    pictureUrls: {
      mobile: certificationMobileImage,
      desktop: certificationDesktopImage,
    },
    breadcrumb: <FormattedHTMLMessage id="guide.slides.certification.breadcrumb" />,
    content: <CertificationSlideContent updateNextButtonDisabled={updateNextButtonDisabled} />,
  }

  const SLIDE_PACE = {
    key: 'pace',
    title: <FormattedHTMLMessage id="guide.slides.pace.title" />,
    pictureUrls: {
      mobile: paceMobileImage,
      desktop: paceDesktopImage,
    },
    breadcrumb: <FormattedHTMLMessage id="guide.slides.pace.breadcrumb" />,
    content: <PaceSlideContent />,
  }

  const VIRTUAL_CLASSROOM = {
    key: 'virtualClassroom',
    title: <FormattedHTMLMessage id="guide.slides.virtualClassroom.title" />,
    pictureUrls: {
      mobile: virtualClassroomMobileImage,
      desktop: virtualClassroomDesktopImage,
    },
    breadcrumb: <FormattedHTMLMessage id="guide.slides.virtualClassroom.breadcrumb" />,
    content: <VirtualClassroomSlideContent />,
  }

  const GOALS = {
    key: 'goals',
    title: <FormattedHTMLMessage id="guide.slides.goals.title" />,
    pictureUrls: {
      mobile: goalsMobileImage,
      desktop: goalsDesktopImage,
    },
    breadcrumb: <FormattedHTMLMessage id="guide.slides.goals.breadcrumb" />,
    content: <GoalsSlideContent />,
  }

  const COLLECTOR = {
    key: 'collector',
    title: <FormattedHTMLMessage id="guide.slides.collector.title" />,
    pictureUrls: {
      mobile: collectorMobileImage,
      desktop: collectorDesktopImage,
    },
    breadcrumb: <FormattedHTMLMessage id="guide.slides.collector.breadcrumb" />,
    content: <CollectorSlideContent onSubmit={handleFinish} />,
    removeNavigationButton: true,
  }

  useEffect(() => {
    const configuration = [SLIDE_ONBOARDING]

    if (hasUsedCpf) {
      configuration.push(SLIDE_CERTIFICATION)
    }

    if (!isCustom) {
      configuration.push(SLIDE_PACE)
    }

    if (hasVirtualClassroom && hasRSVP) {
      configuration.push(VIRTUAL_CLASSROOM)
    }

    if (hasBic && hasBfc) {
      configuration.push(GOALS)
    }

    if (hasBicOnboarding && !bicOnboarding.answered) {
      configuration.push(COLLECTOR)
    }

    updateSlideConfiguration(configuration)
  }, [])

  const changeSlidePage = newPage => {
    push(getSlidePage(newPage))
    animateScrollTo(document.body, { duration: 0 })
  }

  const handleClickPreviousButton = () => {
    changeSlidePage(Math.max(1, parseInt(slidePage, 10) - 1))
  }

  const handleClickNextButton = () => {
    changeSlidePage(Math.min(slideConfiguration.length, parseInt(slidePage, 10) + 1))
  }

  if (!slideConfiguration) {
    return null
  }

  const slideIndex = slidePage - 1

  return (
    <>
      <Helmet
        titleTemplate={I18nProvider.formatMessage({ id: 'guide.head_title_template' })}
        title={I18nProvider.formatMessage({ id: `guide.slides.${slideConfiguration[slideIndex].key}.head_title` })}
      />
      <SlideLayout
        key={slideConfiguration[slideIndex].key}
        breadcrumb={<Breadcrumb slideIndex={slideIndex} slideConfiguration={slideConfiguration} />}
        title={slideConfiguration[slideIndex].title}
        pictureUrls={slideConfiguration[slideIndex].pictureUrls}
        content={slideConfiguration[slideIndex].content}
        currentSlide={slideIndex + 1}
        totalSlideNumber={slideConfiguration.length}
        removeNavigationButton={slideConfiguration[slideIndex].removeNavigationButton ?? false}
        previousButton={
          slideIndex !== 0 && (
            <Button onClick={handleClickPreviousButton} theme="link">
              <FormattedMessage id="pagination.previous_label" />
            </Button>
          )
        }
        nextButton={
          slideIndex < slideConfiguration.length - 1 ? (
            <Button onClick={handleClickNextButton} disabled={nextButtonDisabled}>
              <FormattedMessage id="pagination.next_label" />
            </Button>
          ) : (
            <Button onClick={handleFinish} disabled={nextButtonDisabled}>
              <FormattedMessage id="guide.button_label_end" />
            </Button>
          )
        }
        leaveButton={
          lightMode && (
            <Button onClick={onLeave} theme="link" style={{ padding: 0 }}>
              <FormattedMessage id="guide.button_label_leave" />
            </Button>
          )
        }
      />
    </>
  )
}

Guide.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      slidePage: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  getSlidePage: PropTypes.func.isRequired,
  onLeave: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  lightMode: PropTypes.bool.isRequired,
}

export default Guide
